import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Volunteering with CompDem`}</h1>
    <p>{`Thank you so much for your interest in helping!`}</p>
    <p>{`The Computational Democracy Project offers several opportunities for volunteers to further the application of data science methods to deliberative democracy.`}</p>
    <p>{`To learn more about our mission, vision and approach, see: `}<a parentName="p" {...{
        "href": "/about",
        "title": "about"
      }}>{`about`}</a>{``}</p>
    <h3>{`Data Scientists`}</h3>
    <p>{`Volunteer data scientists are highly leveraged and help CompDem in a number of ways, in both applied and theoretical aspects.`}</p>
    <p>{`From pairing with a newsrooms to help them understand and analyze the data they've collected, to developing and validating new methods of understanding populations at scale, there are lots of ways you can plug in and have an impact.`}</p>
    {
      /*For technical background, see: [[data scientist onboard]]*/
    }
    <h3>{`Web Engineers`}</h3>
    <p>{`Contributing to development of the Polis platform itself is another great way to help.
We have a small but enthusiastic core of volunteers looking to help maintain and improve Polis, and make it easier to deploy.`}</p>
    <h3>{`Volunteer form`}</h3>
    <p>{`Please fill out the information below so we can better sense of how you'd be able to contribute to the Project.`}</p>
    <div style={{
      "marginLeft": "-33px"
    }}>
  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe21X1MMZxZM82MJ5FUVYdotgZ813-JvdhXbEU9q0rwakzEdw/viewform?embedded=true" width="640" height="1800" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      